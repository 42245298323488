import Mock from "../mock";

const database = {
  information: {
    name: 'Wesley H. Canosa',
    aboutContent: "I am system analyst, full stack developer and a scrum master. I've worked with .Net C# for seven years and I am also diving into nodejs, reactjs and react native for three years. I work with a small team, trying everyday to improve ourselves, so we can face the challenges ahead of us and rise to the occasion.",
    age: 34,
    phone: '+55 (14) 99847-8601',
    nationality: 'Brazilian/Italian',
    language: 'Portuguese, English',
    email: 'wesley.canosa@gmail.com',
    address: '',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/wesley-canosa-820a6817/',
      dribbble: '',
      github: 'https://github.com/asonac'
    },
    brandImage: 'https://avatars2.githubusercontent.com/u/53045953?s=460&u=12a5d56ea22d71b52d2ad6d44d2031404b5ac964&v=4',
    aboutImage: '/images/IMG_7250.jpg',
    aboutImageLg: '/images/IMG_7250.jpg',
    cvfile: '/files/Resume-Wesley-Canosa.pdf'
  },
  services: [
    {
      title: "Back End Application",
      icon: 'cog',
      details: "Technologies: C#.Net, NodeJs\n Databases: SQLServer, Postgres, MongoDB, Redis"
    },
    {
      title: "Web Application",
      icon: 'code',
      details: `Primary Technology: ReactJs Secondary: HTML, CSS, Javascript, JQuery, SCSS`
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "React Native"
    }
  ],
  reviews: [

    {
      id: 2,
      content: "I had the chance to work with Wesley as a programming partner and team leader. He has always embraced new technologies and methods to solve challenges and difficulties, seeking to add value to his team and provide quality service.",
      author: {
        name: 'Leonardo Belini',
        designation: 'Full Stack Developer, Valore Brasil'
      }
    },
    {
      id: 3,
      content: "Wesley is an incredible professional and in the 2 years we worked together he added a lot with his experience and concepts of agile methodology, being a notable Scrum Master and always encouraging each one of the team to do their best to face the challenges of everyday life.",
      author: {
        name: 'Celso Reis',
        designation: 'Full Stack Developer, Laboratório Sodré.'
      }
    },
    {
      id: 5,
      content: "I am very grateful to have worked with him as a leader, his posture and experience becomes an indispensable person in any team, both in management and in knowledge of the Stacks, I thank Wesley for having shared his knowledge with me . I owe him a lot.",
      author: {
        name: 'Hugo Guedes',
        designation: 'Full Stack Developer, LG Lugar de Gente.'
      }
    },
    {
      id: 1,
      content: "I had the pleasure of working with Wesley since he started his professional career, we collaborated together on several projects and throughout that time he showed himself to be a person with a lot of competence, commitment and willingness to always learn.",
      author: {
        name: 'Pedro Maitan',
        designation: 'IT Manager, Laboratório Sodré'
      }
    },
    {
      id: 4,
      content: "I had the opportunity to join an agile team under Wesley's leadership. It was a great experience, as he was able to play both the role of a technical leader and a scrum master, always seeking the maximum engagement of the team in order to provide quality deliveries.",
      author: {
        name: 'Naoshi Arimori',
        designation: 'Full Stack Developer, Laboratório Sodré.'
      }
    },

  ],
  skills: [
    {
      title: "NodeJs",
      value: 90
    },
    {
      title: "C#.NET",
      value: 90
    },
    {
      title: "ReactJs",
      value: 85
    },
    {
      title: "React Native",
      value: 85
    },
    {
      title: "Javascript",
      value: 90
    },
    {
      title: "JQuery",
      value: 80
    },
    {
      title: "SQL SERVER",
      value: 85
    },
    {
      title: "Postgres",
      value: 75
    },
    {
      title: "Redis",
      value: 85
    },
    {
      title: "MongoDB",
      value: 75
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "GoBarber",
      subtitle: "Web & Mobile Application",
      imageUrl: "/images/gobarber/rsz_login.png",
      largeImageUrl: [
        "/images/gobarber/login.png",
        "/images/gobarber/register.png",
        "/images/gobarber/appointments.png",
        "/images/gobarber/profile.png",
        "/images/gobarber/mobile_splash.png",
        "/images/gobarber/mobile_login.png",
        "/images/gobarber/mobile_appointments.png",
        "/images/gobarber/mobile_barbers.png",
        "/images/gobarber/mobile_profile.png"
    ],
      url: 'https://gobarber.celecsys.com/'
    },
    {
      id: 2,
      title: "Ecoleta",
      subtitle: "Web & Mobile Application",
      imageUrl: "/images/rsz_szv_ecoleta.png",
      largeImageUrl: [
        "/images/ecoleta.png"
      ],
      url: 'https://github.com/asonac/nlw-01'
    },
    {
      id: 3,
      title: "GoRestaurant",
      subtitle: "Web & Mobile Application",
      imageUrl: "/images/gorestaurant/rsz_gorestaurant.png",
      largeImageUrl: [
        "/images/gorestaurant/GoRestaurant.png",
        "/images/gorestaurant/mobile1.png",
        "/images/gorestaurant/mobile2.png",
        "/images/gorestaurant/mobile3.png"
      ],
      url: 'https://github.com/asonac/GoRestaurant-App'
    },
    {
      id: 4,
      title: "GoFinances",
      subtitle: "Web Application",
      imageUrl: "/images/gofinances/rsz_list.png",
      largeImageUrl: ["/images/gofinances/list.png", "/images/gofinances/import.png"],
      url: 'https://github.com/asonac/gostack-modulo3-desafio7-react'
    },
    {
      id: 5,
      title: "GoMarketplace",
      subtitle: "Mobile Application",
      imageUrl: "/images/gomarketplace/rsz_app.png",
      largeImageUrl: ["/images/gomarketplace/list.png","/images/gomarketplace/shopping_cart.png"],

    },
    {
      id: 6,
      title: "Github Explorer",
      subtitle: "Web Application",
      imageUrl: "/images/github_explorer/rsz_seach.png",
      largeImageUrl: ["/images/github_explorer/seach.png", "/images/github_explorer/more_info.png"],
      url: 'https://github.com/asonac/gostack-modulo2-react-git'
    },
    {
      id: 7,
      title: "Be The Hero",
      subtitle: "Web & Mobile Application",
      imageUrl: "/images/bethehero/rsz_bethehero.png",
      largeImageUrl: ["/images/bethehero/bethehero2.png",
      "/images/bethehero/list.png",
      "/images/bethehero/register.png",
      "/images/bethehero/necase.png",
      "/images/bethehero/splash.png",
      "/images/bethehero/mobile-cases.png",
      "/images/bethehero/mobile-bethehero.png"],
      url: 'https://github.com/asonac/BeTheHero'
    },
    {
      id: 8,
      title: "CelecSys",
      subtitle: "Web Application",
      imageUrl: "/images/webcelecsys/rsz_celecsysweb.png",
      largeImageUrl: ["/images/webcelecsys/celecsysweb.png"],
      url: 'http://celecsys.com.br'
    },
    {
      id: 9,
      title: "PecManager",
      subtitle: "Web Application",
      imageUrl: "/images/pecmanager/rsz_pec.png",
      largeImageUrl: ["/images/pecmanager/pec.png",
      "/images/pecmanager/pec2.png",
      "/images/pecmanager/inside.png"
    ],
      url: 'http://pec.celecsys.com.br'
    },
    {
      id: 10,
      title: "Okida Odontologia",
      subtitle: "Web Application",
      imageUrl: "/images/okidaodontologia/rsz_okida.png",
      largeImageUrl: ["/images/okidaodontologia/okida.png",
      "/images/okidaodontologia/okida2.png",
      "/images/okidaodontologia/okida3.png",
      "/images/okidaodontologia/okida4.png"],
      url: 'http://okidaodonto.com'
    },
    {
      id: 11,
      title: "Okida Odontologia Manager",
      subtitle: "Windows Application",
      imageUrl: "/images/manager/rsz_initial_page.png",
      largeImageUrl: [
        "/images/manager/initial_page.png",
        "/images/manager/manager.png",
        "/images/manager/manager2.png",
        "/images/manager/mana3.png",
      ],
      url: 'https://github.com/asonac/appointment-cashflow-manager.git'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Present",
        position: "System Analyst | Full Stack Developer | SCRUM Master",
        company: "Laboratório Sodré",
        details: "Designed, developed and sustained the entire production system, from managing their chain of custody to delivering the results to the clients."
      },
      {
        id: 2,
        year: "2015 - 2017",
        position: "System Analyst | Full Stack Developer",
        company: "Celecsys",
        details: "Designed and developed a ERP system for livestock. Currently working with C# .NET, Visual Studio, SQLServer and many others technologies."
      },
      {
        id: 3,
        year: "2014 - 1014",
        position: "ABAP Developer",
        company: "Advanced ITeam Soluções e Serviços de TI Ltda",
        details: "Application Developer (SAP-ABAP) - ALV, Module Pool."
      },
      {
        id: 4,
        year: "2013 - 2014",
        position: "Web Developer",
        company: "CTGEO - CENTRO DE GEOPROCESSAMENTO",
        details: "I started the internship in my first year of college. I partook in a projects rotation, learning C#, SQL among several other technologies"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2020",
        graduation: "RocketSeat GoStack Bootcamp",
        university: "RocketSeat",
        details: "Bootcamp to improve my knowledge of NodeJs, ReactJs and React Native."
      },
      {
        id: 2,
        year: "2019",
        graduation: "Scrum Master Professional Certificate (SMPC)",
        university: "CertiProf",
        details: "SCRUM is easy to understand and hard to master."
      },
      {
        id: 3,
        year: "2019",
        graduation: "Scrum Foundation Professional Certificate (SFPC)",
        university: "CertiProf",
        details: "SCRUM fundamentals certification."
      },
      {
        id: 4,
        year: "2013 - 2015",
        graduation: "Computer Systems Analysis, Information Technology",
        university: "Unilins",
        details: "Analysis and design techniques to solve business problems using information technology. Systems analysts may serve as change agents who identify the organizational improvements needed, design systems to implement those changes, and train and motivate others to use the systems"
      },
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+55 (14) 99847-8601'],
    emailAddress: ['wesley.canosa@gmail.com'],
    address: "Rua José Telles, 180, Lins, São Paulo, Brazil"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
